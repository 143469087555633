
body {
  background: url('https://localprojectapi-api.vercel.app/file/1699048490429-any-name-wdwdw.jpg?w=248&fit=crop&auto=format');
  background-size: cover;
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  background-repeat: no-repeat;
  margin: 0; /* Remove default margin */
  padding: 0 !important;/* Add some padding */
  font-family: 'Roboto', sans-serif;
}

.imgLogin{
  background-size: cover;
  background: url('https://localprojectapi-api.vercel.app/file/1699048490429-any-name-wdwdw.jpg?w=248&fit=crop&auto=format');
}

.outlined-textfield .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2196f3; /* Change the color as needed */
  transition: border-color 0.3s; /* Adjust the animation duration as needed */
}

.outlined-textfield .Mui-focused .MuiOutlinedInput-notchedOutline:hover {
  border-color: #1976d2; /* Change the hover color as needed */
}

/* Additional styles for the text field */
.outlined-textfield .MuiOutlinedInput-root {
  border-color: #000; /* Initial border color */
}

.tree {
  position: relative;
  transform: translateX(200px);
}

.trunk {
  width: 20px;
  height: 100px;
  background-color: brown;
  margin: 0 auto;
  position: relative;
}

.branch {
  background-color: green;
  position: absolute;
}

.main-branch {
  width: 10px;
  height: 100px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sub-branch {
  width: 1px;
  height: 80px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: growBranch 7s linear infinite;
}

.sub-branch1 {
  width: 1px;
  height: 180px;
  top: 20px;
  left: 60%;
  transform: translateX(-30%);
  animation: growBranch 6s linear infinite;
}

@keyframes growBranch {
  0% {
    height: 0;
  }
  100% {
    height: 180px;
  }
}

.hue-picker-bar {
  display: none;
}

.centered-text {
  font-weight: 800; /* Equivalent to font-extrabold */
  letter-spacing: -0.01em;
  line-height: 1; /* Equivalent to leading-none */
  font-size: 20px; /* Default font size for all screen sizes */
  color: #444444;
  transition: color 1.5s;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
   /* Take the full viewport height */
}

//Leaf Spinner
@keyframes leafanimation {
  0% {transform:translate(900px,500px) rotate(0deg); color:rgba(255,0,0,0);}
 20% {transform:translate(850px,450px) rotate(360deg); color:rgba(255,0,0,0);}
 40% {transform:translate(450px,200px) rotate(720deg); color:rgba(255,0,0,0);}
 60% {transform:translate(100px,100px) rotate(1080deg); color:rgba(255,0,0,0);}
 80% {transform:translate(0,0) rotate(1440deg); color:rgba(255,0,0,0);}
100% {transform:translate(0,0) rotate(1440deg); color:rgba(255,0,0,1);}
}

.my-logo {
position:absolute; 
top:0;
left:0;
height: 40px;
line-height: 40px;
font-size: 36px;
color: rgb(255,0,0);
animation: leafanimation 6s linear;
}

.my-logo div {
display: inline-block;
width: 20px;
height: 40px;
vertical-align: middle;
background-color: rgb(255,0,0);
border-radius: 20px;
}



@media (min-width: 768px) {
  .centered-text {
    font-size: 30px; /* Applied on medium-sized screens (md) */
    max-width: 50rem; /* Equivalent to max-w-lg */
  }
  .hide-testimonial {
    display: none; /* Hide the testimonial on smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .hide-testimonial {
    display: none; /* Hide the testimonial on smaller screens */
  }
}

@media (min-width: 1024px) {
  .centered-text {
    font-size: 40px; /* Applied on large screens (lg) */
    max-width: 48rem; /* Equivalent to lg:max-w-4xl */
  }
}

.custom-content {
  background-color: #3498db; /* Blue background color */
  color: #ffffff; /* White text color */
  padding: 33px;
  transition: background-color 0.2s; /* Smooth transition on hover */
}

.custom-content:hover {
  background-color: #f5f7f8; /* Slightly darker blue background on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Glimmering effect on hover with a subtle shadow */
}

#status-container {
  display: inline-block;
  height: 1px; /* Set the desired height */
  margin: 0;
  background-color: transparent;
}

.status-squares {
  display: flex; /* Make squares appear horizontally */
  align-items: center; /* Center vertically within the container */
}

.hoverable-element {
  opacity: 0;
  transition: opacity 0.5s; /* You can adjust the transition duration as needed */
}

.hoverable-element:hover {
  opacity: 1;
}

.square {
  width: 5px;
  height: 1px;
  background-color: lightseagreen;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.square.down {
  background-color: red;
}

.dotted-line {
  width: 1px; /* Adjust the width of the line as needed */
  height: 20px; /* Adjust the height of the line as needed */
  border-left: 1px dotted black; /* Adjust the color as needed */
}

.css-6hp17o-MuiList-root-MuiMenu-list{
padding-top: 8px !important;
padding-bottom: 8px !important;
}

.webcam-recorder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.live-video {
  border: 2px solid #ff3333;
  border-radius: 12px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
}

.record-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ff3333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.record-button.recording {
  background-color: #990000;
}

.live-video, .recorded-video {
  margin-top: 20px;
  border: 2px solid #ff3333;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.recorded-video {
  margin-top: 40px;
}


@keyframes rollHills {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOff {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.form-group {
  position: relative;
}

.form-control {
  border: 2px solid #ccc;
  padding: 15px;
  width: 300px; /* Adjust width as needed */
  font-size: 18px;
  transition: border-color 0.3s ease-in-out;
}

.form-control.has-content {
  animation: slideOff 0.5s ease-out forwards;
}

.form-label {
  position: absolute;
  left: 15px;
  top: 10px;
  font-size: 18px;
  color: #00bcd4;
  pointer-events: none;
}





Grid{
  background-color: #f5f5f5!important;
}

.cards {
  background-color: #f5f5f5!important;
  background: linear-gradient(to bottom right, #2196f3, #e91e63);
  transform-style: preserve-3d;
  transform: rotateX(11deg) rotateY(16.5deg);
}

.video-container {
  position: relative;
  width: 100%;
  height: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.video-container video {
  width: 100%; 
  height: 80%;
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
}

#content {
  flex: 1 1;
  order: 2;
  display: flex;
}

.search-icon {
  animation: spin 2s linear infinite;
}

.button {
 
  background-color: rgb(64, 115, 202);
}

#mapPane {
  flex: 1;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.leaflet-container {
  margin-top: 10px;
  position: fixed;
  width: 100%;
  position: relative;
  display: inline-flex;
  height: 30vh;
}

.mrgnbtm {
  margin-top: 20px;
}

.btncenter {
  text-align: center;
}

.mrgnttm10 {
  margin-bottom: 10px;
}

.coverImg {
  border-block-start-color: goldenrod;
}

label,
input {
  display: block;
  width: 100%;
}

input {
  margin-bottom: 1em;
}

ul {
  display: flex;
  flex-wrap: wrap;
}

li {
  flex-grow: 1;
}

img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }

  img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
}


/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}

.leaflet-container:before,
.leaflet-container:after {
  content: "";
}

/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 6px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}

.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results>.active,
.leaflet-control-geosearch .results> :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, .2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

/* animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaflet-top .leaflet-control-geosearch.bar,
.leaflet-bottom .leaflet-control-geosearch.bar {
  display: none;
}

.leaflet-control-geosearch.bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-control-geosearch.bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.leaflet-control-geosearch.bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-control-geosearch.bar .results.active:after {
  opacity: .2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}

//Mesages in chat
.msg-list {
  width: 100%;
}

.messenger-container {
  padding: 8px 15px 8px 13px;
  overflow: hidden;
  margin: 1px 0 25px 35px; 
  float: left;
  transform-origin: 0 100%;
  max-width: 82%;
  background: #f2f2f2;
  border-radius: 10px;
  min-width: 20%;
  position: relative;
  box-sizing: border-box;
  box-shadow: 7px 10px 6px -5px #c2d3eb;
}

.messenger-container p {
  color: #3D3D3D;
  font-size: 12px;
  margin-bottom: 6px;
  line-height: 18px;
  word-wrap: break-word;
  margin: 0;
}

.sender .messenger-container {
  float: right;
  margin-right: 35px;
  width: auto;
  background: #D5DBFF;
  margin-left: 0px;
  padding: 8px 15px 8px 13px;
}

.clear {
  clear: both;
  width: 100%;
  padding: 0px !important;
  margin: 0px;
  height: 0px;
}

.messenger-container::before {
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 15px solid #f2f2f2;
  content: "";
  position: absolute;
  top: 9px;
  left: -15px;
}

.sender .messenger-container::before {
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 15px solid #D5DBFF;
  content: "";
  position: absolute;
  top: 9px;
  left: 99%;
  border-right: none;
}

//animate Message
*,
*::before {
  box-sizing: border-box;
}

.chat {
  display: flex;
  flex-direction: column-reverse;
  height: 12rem;
  max-width: 82%;
  overflow: hidden;
  box-shadow: 1px 1px 1px 0 #c2d3eb;
  border: 1px #ccc dashed;
  font: .85rem/1.5 Arial;
  color: #313131;
  position: relative;
}

.chat::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  height: 40%;
  width: 100%;
  background: linear-gradient(to bottom, white 20%, rgba(255, 255, 255, 0)) repeat-x;
}

.chat p {
  margin: 0;
  padding: 0;
}

.chat__content {
  flex: 0 1 auto;
  max-width: 82%;
  padding: 1rem;
  margin: 0 0.5rem;
  background: var(--bgcolor);
  border-radius: var(--radius);
}

.chat__content_b {
  flex: 0 1 auto;
  max-width: 99%;
  padding: 1rem;
  margin: 0 0.5rem;
  background: linear-gradient(to bottom, white 20%, rgba(255, 255, 255, 0)) repeat-x;
  border-radius: var(--radius);
  float: right;
}

.chat__message {
  width: 95%;
  display: flex;
  align-items: flex-end;
  transform-origin: 0 100%;
  padding-top: 0;
  transform: scale(0);
  max-height: 0;
  overflow: hidden;
  animation: message 0.15s ease-out 0s forwards;
  animation-delay: var(--delay);
  --bgcolor: #b6dfcc;
  --radius: 8px 8px 8px 0;
}

.chat__message_B {
  flex-direction: row-reverse;
  text-align: right;
  align-self: flex-end;
  transform-origin: 100% 100%;
  --bgcolor: #d2ecd4;
  --radius: 8px 8px 0 8px;
  float: right;
}

.chat__message::before {
  flex: 0 0 40px;
  aspect-ratio: 1/1;
  background: var(--bgcolor);
  border-radius: 50%;
}

@keyframes message {
  0% {
    max-height: 100vmax;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
    padding-top: 0rem;
  }
}

@mixin center() {
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.subContainer {
  position: absolute;
  @include center();
}

.subTitle {
  font-weight: 1000;
  color: transparent;
  font-size: 70px;
  background-blend-mode: multiply; 
  background-position: 50% 50%;
  position: relative;
  text-align: center;
  line-height: 65px;
  background-clip: text;
  animation: animate 120s;
  animation-iteration-count: infinite;
}

//#C9A959 gold
.tasks-column {
  /* other styles */
  transition: background-color 0.2s ease-in-out;
  &.dragging-over {
    background-color: #d9d9d9;
  }
}

.buttonStyle {
   /* fallback color */  
 
  /* darken the image */
  /* change the text color to white */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.buttonStyle:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply; /* darken the image */
  background-blend-mode: color; 
}

.appCover {
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.appCoverReply {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  -webkit-text-stroke: 1px black;
  background-position: 35% 45%;
}

.buttonStyleTodo {
  background: linear-gradient(to bottom right, #e6b7ab, #ebe6d9);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.buttonStyleTodo:hover {
  background: linear-gradient(to bottom right, #e7e1d2, #dbd3bb);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

.buttonStyleCompleted {
  background: linear-gradient(to bottom right,  #529ce1, #9289e0);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.buttonStyleCompleted:hover {
  background: linear-gradient(to bottom right, #529ce1, #9289e0);
  box-shadow: 0 8px 20px #529ce1;
  transform: translateY(-3px);
}




img.MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img.css-1uez2a8-MuiCardMedia-root {
  padding-top: 0%;
}

.footer {
  background-color: whitesmoke;
}

.createUserPic {
  background-position: center;
  background-image: -webkit-image-set('/src/images/friends.webp');
  background-repeat: no-repeat; 
  background-size: contain;
}

.createUserFriends {
  background-image: -webkit-image-set('/src/images/friends.webp');
  background-repeat: no-repeat; 
  background-position: left;
  background-size: contain;
}

.createQuestionPic {
  background-image: -webkit-image-set('/src/images/questions_1.webp');
  background-repeat: no-repeat; 
  background-position: center;
  background-size: contain;
}

.subTitleHome {
  font-weight: 1000;
  color: transparent;
  font-size: 35px;
  background-color: rgb(162, 162, 201);
  background-position: 50% 50%;
  position: relative;
  text-align: center;
  line-height: 45px;
  //  -webkit-background-clip: text;
 // animation: animate 126s;
 // animation-iteration-count: infinite;
}

.subTitleTest {
  color: transparent;
  background-position: 10% 10%;
  position: relative;
  text-align: center;
  background-clip: background;
}

.progress {
  background-position: 25% 25%;
  text-align: center;
  line-height: 65px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100%;
  margin-bottom: 20px;
  position: fixed;
  z-index: 100;
}

.center {
  position: absolute;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0.2;
  transition: opacity 0.5s ease-out;
}

.bob {
  width: 300px;
  height: 200px;
  background-color: lightgray;
  margin: 20px;
  display: inline-block;
  perspective: 1000px;
}

.bob:hover {
  transform: rotateY(5deg);
}

.bob-content {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0deg);
}

.bob-front,
.bob-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bob-front {
  background-color: white;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.image-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  cursor: pointer;
}

.TestimonialAvatar--active {
  opacity: 1;
}

.TestimonialAvatar--inactive {
  opacity: 0;
}

.avatar-container {
  display: flex;
  flex-direction: row !important;
  margin-top: 1px;
  align-items: center;
}

#header {
  color: #00f;
}

#leaves {position:relative;top:-50px;width:100%;text-align: right;}

#leaves i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    z-index: 1;
    -webkit-animation: falling 5s 0s infinite;
}

#leaves i:nth-of-type(2n) { -webkit-animation: falling2 5s 0s infinite; }
#leaves i:nth-of-type(3n) { -webkit-animation: falling3 5s 0s infinite; }

#leaves i:before {
  position: absolute;
  content: '';
  top: 117px;
  right: 9px;
  height: 27px;
  width: 32px;
  transform: rotate(49deg);
  border-radius: 0% 15% 15% 0%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 0px solid #222;
  border-right: 1px solid #222;
  background: linear-gradient(to right, rgba(0,100,0,1), #005600);
  z-index: 1;
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0,0,0,.15), rgba(0,0,0,0));
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius:50%;
}


#leaves i:nth-of-type(n)    { height:23px; width:30px; }
#leaves i:nth-of-type(n):before { width:7px; height:5px; top:17px; right:1px; }
#leaves i:nth-of-type(n):after { width:2px; height:17px; left: 12px; top:0px; }

#leaves i:nth-of-type(2n+1)    { height:11px; width:16px; }
#leaves i:nth-of-type(2n+1):before { width:4px; height:3px; top:7px; right:0px; }
#leaves i:nth-of-type(2n+1):after { width:2px; height:6px; left: 5px; top:1px; }

#leaves i:nth-of-type(3n+2)  { height:17px; width:23px; }
#leaves i:nth-of-type(3n+2):before  { height:4px; width:4px; top:12px; right:1px; }
#leaves i:nth-of-type(3n+2):after  { height:10px; width:2px; top:1px; left:8px; }

#leaves i:nth-of-type(n)   { -webkit-animation-delay: 1.9s;}
#leaves i:nth-of-type(2n)  { -webkit-animation-delay: 3.9s;}
#leaves i:nth-of-type(3n)  { -webkit-animation-delay: 2.3s;}
#leaves i:nth-of-type(4n)  { -webkit-animation-delay: 4.4s;}
#leaves i:nth-of-type(5n)  { -webkit-animation-delay: 5s;  }
#leaves i:nth-of-type(6n)  { -webkit-animation-delay: 3.5s;}
#leaves i:nth-of-type(7n)  { -webkit-animation-delay: 2.8s;}
#leaves i:nth-of-type(8n)  { -webkit-animation-delay: 1.5s;}
#leaves i:nth-of-type(9n)  { -webkit-animation-delay: 3.3s;}
#leaves i:nth-of-type(10n) { -webkit-animation-delay: 2.5s;}
#leaves i:nth-of-type(11n) { -webkit-animation-delay: 1.2s;}
#leaves i:nth-of-type(12n) { -webkit-animation-delay: 4.1s;}
#leaves i:nth-of-type(13n) { -webkit-animation-delay: 1s;  }
#leaves i:nth-of-type(14n) { -webkit-animation-delay: 4.7s;}
#leaves i:nth-of-type(15n) { -webkit-animation-delay: 3s;  }

#leaves i:nth-of-type(n)    { background: linear-gradient(to bottom right, #309900, #005600); }
#leaves i:nth-of-type(2n+2)  { background: linear-gradient(to bottom right, #5e9900, #2b5600); }
#leaves i:nth-of-type(4n+1)  { background: linear-gradient(to bottom right, #990, #564500); }

#leaves i:nth-of-type(n)    { opacity: .7;}
#leaves i:nth-of-type(3n+1)  { opacity: .5;}
#leaves i:nth-of-type(3n+2)  { opacity: .3;}

#leaves i:nth-of-type(n)    {transform: rotate(180deg);}


#leaves i:nth-of-type(n) { -animation-timing-function:ease-in-out;}

@keyframes falling {
    
    0% {
        -webkit-transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-350px,700px,0)
            rotate(90deg);
        opacity: 0;
    }
}

@keyframes falling3 {
     0% {
        -webkit-transform:
            translate3d(0,0,0)
            rotate(-20deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-230px,640px,0)
            rotate(-70deg);
        opacity: 0;
    }
}

@keyframes falling2 {
     0% {
        -webkit-transform:
            translate3d(0,0,0)
            rotate(90deg);
    }
    
    100% {
        -webkit-transform:
            translate3d(-400px,680px,0)
            rotate(0deg);
        opacity: 0;
    }
}